<template>
    <!-- 讲座 -->
    <div class="Lecindex">
        <ul>
            <li v-for="item in Data" :key="item.id">
                <span class="span2">{{ item.name }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Data: [
                { id: 1, name: '1.专题讲座：职业心理健康' },
                { id: 2, name: '2.专题讲座：身心疾病与心理健康' },
                { id: 3, name: '3.专题讲座：积极心理与幸福人生' },
                { id: 4, name: '4.主题沙龙：压力管理' },
                { id: 5, name: '5.主题沙龙：人际关系与沟通技巧' },
                { id: 6, name: '6.主题沙龙：情绪管理' },
                { id: 7, name: '7.主题沙龙：爱的语言——创造完美的夫妻沟通沙龙方案' },
                { id: 8, name: '8.专题讲座：恋爱、婚姻、家庭' },
                { id: 9, name: '9.主题沙龙：现代子女教育' },
                { id: 10, name: '10.专题讲座：工作与家庭的平衡' }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.Lecindex {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin-top: 15px;
            color: var(--memoFont-color);
            line-height: 20px;
            // cursor: ;
        }
    }
}
</style>