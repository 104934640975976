<template>
  <div class="index">
    <!-- 讲座 -->
    
    <div>
      <shortLec></shortLec>
    </div>
    <div class="btn1">
      <span class="span2" @click="toProgramme">返回服务内容</span>
    </div>
  </div>
</template>

<script>
import shortLec from './short/shortLec.vue';
export default {
  components: {shortLec},
  methods: {
    toProgramme() {
            this.$router.push({
                path: '/enterprise/programme',
                query: {
                    id:this.$route.query.id
                }            
            })
        }
  }
}
</script>

<style lang="scss" scoped>
.index{
  padding: 30px 0px;

}
</style>